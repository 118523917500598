import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const Consulting = () => {
  return (
    <Layout>
      <SEO title="Consulting" description="" />
      <div className="consulting-wrapper wrapper py-5">
        <div className="row">
          <div className="col-12">
            <Image src="CONSULTANCY.webp" />
          </div>
        </div>
        <h1 className="mt-3">Consulting</h1>
        <span>
          Intermedia Marketing Group offers expertise on a consulting basis in
          the following areas:
        </span>
        <ul>
          <li>New website design</li>
          <li>Redesign existing websites</li>
          <li>Implement Search Engine Optimization (SEO) structure</li>
          <li>
            Expand social media reach on LinkedIn, Facebook, Twitter, Pinterest,
            and others
          </li>
          <li>
            Setup and manage: Email newsletter programs, Pay-per-click campaigns
            on major search engines (Google, Yahoo, Bing), Display and video
            advertising media buys
          </li>
        </ul>
        <p>
          With almost a decade of experience in the online marketing industry,
          Patrick Donaldson has established a worldwide network of professional
          marketers to help provide quality results for each and every client.
          His past roles include Marketing for a large online retailer, SEO for
          an online company offering custom registrations, Business Development
          for a lead generation company focused in the insurance vertical, as
          well as General Manager and Co-Founder of an advertising and publisher
          network still in business today.
        </p>
      </div>
    </Layout>
  )
}

export default Consulting
